//
// Text
//

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

// Contextual colors

.text-white { color: #fff !important; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

.text-muted { color: $text-muted !important; }

// Misc

.text-hide {
  @include text-hide();
}
