
	// Styles de formulaire
		
		// Reset
			
			input[type=number] {
				-moz-appearance: textfield;
			}

			input[type=number]::-webkit-inner-spin-button, 
			input[type=number]::-webkit-outer-spin-button { 
				-webkit-appearance: none; 
				margin: 0; 
			}

			input:-webkit-autofill { // Background Yellow on Chrome
			    -webkit-box-shadow: 0 0 0 3rem white inset;
			}

		// Commun

			label,
			.label,
			.col-form-legend {
				margin-bottom: .2rem;
				font-size: $font-size-sm;
				font-weight: 600;
			}

			.col-form-legend {
				padding: 0;
			}

			.form-title {
				height: 40px;
				position: relative;
				margin: 0 -16px 8px -16px;
				padding: 8px 10px;
				font: 600 $font-size-base $font-stack-bold;
				color: $violet;
				letter-spacing: -.5px;
				border-bottom: 1px solid $input-border-color;
				// cursor: pointer;

				// &:hover {
				// 	background: #dfddea;
				// }

				&:before {
					content: "";
					opacity: 0;
					position: absolute;
					bottom: -1px;
					width: 1px;
					height: 2px;
					background: $blue;
					transition: transform .15s ease-in-out;
				}

				.btn {
					min-width: 24px;
					padding: .25rem .3rem;
					font-size: 12px;
					line-height: 1.1;
				}
			}

			.form-lead {
				color: $blue;
				font-weight: 600;
				font-size: $font-size-sm;
			}