
	// Buttons

		.arcoffice-buttons {
			// position: absolute;
		 //    left: 0;
		 //    bottom: -2px;
		 //    right: 0;
		    // margin-top: 20px;
			margin: 20px -16px 0;
			border-top: 1px solid rgba($black, .15);
			
			a {
				&:hover {
					text-decoration: none;
					background-color: #dfddea;
				}
			}
		}

		.arcoffice-button_arrow {
			width: 50%;
			text-align: center;
			border-bottom: 1px solid rgba($black, .15);

			a {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				height: 80px;
				padding: 10px;
				transition: background .15s ease-in-out;

				i {
					font-size: 30px;
				}

				span {
					width: 100%;
					margin-top: 3px;
					font-size: .75rem;
				}
			}
		}

		.arcoffice-button_prev {
			border-right: 1px solid rgba($black, .15);
		}

		.arcoffice-buttons_pagination {
			padding: 5px 0;
			text-align: center;
			font-size: .8rem;
			border-bottom: 1px solid rgba($black, .15);
		}

		.arcoffice-buttons_utilities {
			[class^='col']  {
				border-bottom: 1px solid rgba($black, .15);

				&:nth-child(even) {
					border-left: 1px solid rgba($black, .15);
				}
			}

			a {
				width: 100%;
				display: block;
				padding: 5px 0;
				text-align: center;
				font-size: .875rem;
			}
		}

		.arcoffice-buttons_delete-doc {
			color: $red;
		}

		.arcoffice-buttons_liste-doc {
			color: $cyan;
		}

		.arcoffice-buttons_add-doc {
			color: $green;
		}