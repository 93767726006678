
	// Navigation

		.navigation-globale {
			padding: 0 1rem;
			background: #efefef;
			box-shadow: 0 5px 10px 0 rgba(62,57,107,0.07), 0 2px 9px 0 rgba(62,57,107,0.06);

			.container {
				padding: 0;
			}

			.navbar-brand {
				font-family: $font-stack-bold;
				color: $violet;
			}
			
			.nav-item {
				&:not(:last-of-type) {
					margin-left: 10px;
				}
			}

			.nav-link {
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				font: 400 $font-size-sm $font-stack-bold;
				
				&.has-message-alert {
					padding-right: 20px;

					&:before {
						content: attr(data-message-alert);
						position: absolute;
						top: 35%;
						right: 0;
						width: 1rem;
						height: 1rem;
						background: $red;
						border-radius: 50%;
						font-size: 11px;
						text-align: center;
						color: $white;
						display: flex;
					    align-items: center;
					    justify-content: center;
						transform: translateY(-50%);
					}

					&.has-message-sent {
						padding-right: 40px;

						&:before {
							right: 20px;
						}
					}
				}
				span.has-message-alert {
					padding-right: 10px;

					&:before {
						content: attr(data-message-alert);
						position: absolute;
						top: 35%;
						right: 0;
						width: 1rem;
						height: 1rem;
						background: $orange;
						border-radius: 50%;
						font-size: 11px;
						text-align: center;
						color: $white;
						display: flex;
						align-items: center;
						justify-content: center;
						transform: translateY(-50%);
					}
				}

				&.has-message-sent {
					padding-right: 20px;

					&:after {
						content: attr(data-message-sent);
						position: absolute;
						top: 35%;
						right: 0;
						width: 1rem;
						height: 1rem;
						display: flex;
					    align-items: center;
					    justify-content: center;
						background: $blue;
						border-radius: 50%;
						font-size: 11px;
						text-align: center;
						color: $white;
						transform: translateY(-50%);
					}
				}
			}
		}
