
	// Placeholder SASS

		// Clearfix

			%clearfix {
				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}

		// Centrer verticalement (position absolute)

			@mixin center-vertically {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

		// Background Cover

			@mixin bg-cover {
				background: {
					position: center center;
					repeat: no-repeat;
					size: cover;
				}
			}

		// Rotate (Loader)
		@keyframes rotate {
			0% { 
				transform: translate(-50%, -50%);
				transform: rotate(0deg); 
			}
			100% {
				transform: translate(-50%, -50%);
				transform: rotate(360deg);
			}
		}