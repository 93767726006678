		
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			background: #efefef;
		}

		main {
			flex: 1 1 auto;
			margin-top: 40px;
		}

		.sub-header {
			padding: .85rem 0;

			input[type="text"] {
				width: 250px;
			}
		}

		.arcoffice-count {
			color: $breadcrumb-active-color;
			margin-right: 15px;
			font-size: $font-size-sm;
		}

		.section-main  {
			.container {
				box-shadow: 0 5px 20px #cdcbde;
				background: #f5f5f5;

				& > .row {
					border: 1px solid $input-border-color;
				}
			}
		}

		.col-arcoffice {
			padding: 0 15px 8px;
			border: 1px solid transparent;
			background: #f5f5f5;
			transition: background .15s ease-in-out;

			&:not(.is-disabled):not(.is-loading):not(.col-visite):hover,
			&.is-active {
				background: #dfddea;

				.form-title:before {
					opacity: 1;
					transform: scaleX(80);
					transform-origin: left;
				}
			}

			&.is-disabled {
				user-select: none;

				&:before {
					content: "";
					position: absolute;
					z-index: 998;
					top: 40px;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(#e9ecef, .6);
				}
			}

			&.is-loading {
				&:before {
					content: "";
					position: absolute;
					z-index: 9998;
					top: 41px;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba(#f1f1f1, .8);
				}

				&:after {
					content: "";
					position: absolute;
					z-index: 9999;
					top: 50%;
					left: 50%;
					width: 50px;
					height: 50px;
					margin-left: -25px;
					margin-top: -25px;
					border: 4px solid $blue;
					border-right-color: rgba($blue, .4);
					border-radius: 50%;
					animation: rotate .6s infinite linear;
				}
			}

			&:nth-of-type(2) {
				border-left-color: $input-border-color;
				border-right-color: $input-border-color;
			}
		}

		.col-btn-sm {
			max-width: 41px;
		}

		// Pagination

			.arcoffice-pagination {
				width: 100%;
				padding: 10px;
				border-top: 1px solid $input-border-color;
			}

		// Collaspe

			.arcoffice-collapse {
				margin: 5px -16px 10px;
				padding: 8px 15px;
				border-top: 1px solid $input-border-color;
				border-bottom: 1px solid $input-border-color;

				&:hover {
					text-decoration: none;
				}

				i {
					transition: transform .3s ease;
				}
				
				&[aria-expanded="true"] {
					border-bottom-color: transparent;

					i {
						transform: rotate(180deg);
					}
				}
			}

			.collapse {
				position: relative;

				&.show {
					&:before {
						content:"";
						height: 1px;
						z-index: 1;
						position: absolute;
						bottom: -.75rem;
						left: -15px;
						right: -15px;
						background-color: $input-border-color;
					}
				}
			}

		// Modal

			.modal-dialog {
				box-shadow: 0 5px 20px rgba($violet, .4);
			}

			.modal-dialog-left {
				margin: 30px;
			}

			.modal-header-violet {
				background: $violet;

				.modal-title {
					color: $white;
				}

				.close {
					color: $white;
					text-shadow: none;
				}
			}

		// Couleurs

			.arcoffice-jaune {
				&:before {
					background: $yellow !important;
				}
			}

			.arcoffice-violet-clair {
				&:before {
					background: $indigo !important;
				}
			}

			.arcoffice-violet-fonce {
				&:before {
					background: $purple !important;
				}
			}

			.arcoffice-vert {
				&:before {
					background: #39c158 !important;
				}
			}

			.arcoffice-vert-fonce {
				&:before {
					background: #18652a !important;
				}
			}

			.arcoffice-vert-bleu {
				&:before {
					background: #11deaf !important;
				}
			}

			.arcoffice-orange {
				&:before {
					background: $orange !important;
				}
			}

			.arcoffice-rouge {
				&:before {
					background: $red !important;
				}
			}

			.arcoffice-noir {
				&:before {
					background: $black !important;
				}
			}

			.arcoffice-marron {
				&:before {
					background: $brown !important;
				}
			}

			.arcoffice-bleu {
				&:before {
					background: $blue !important;
				}
			}

			.arcoffice-orange {
				&:before {
					background: $orange !important;
				}
			}

		.footer-liens {
			a {
				// font-weight: bold;
			}
			img {
				width: 16.5px;
				height: 16.5px;
			}
		}