.fc-theme-standard .fc-list-day-cushion {
  background: #dfddea !important;
}

.fc .fc-toolbar.fc-header-toolbar {
	margin-top: 1rem;
}

.fc-theme-standard {
	padding-bottom: 1rem;
}